// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/iminoso/Code/developer-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/iminoso/Code/developer-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/Users/iminoso/Code/developer-site/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-sign-up-js": () => import("/Users/iminoso/Code/developer-site/src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */)
}

